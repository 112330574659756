<template>
  <base-section id="products-spec">
    <v-container>
      <v-row>
        <v-col
          v-for="(news, i) in articles"
          :key="i"
          cols="12"
          md="6"
        >
          <news-card-products
            v-bind="news"
          />
        </v-col>
      </v-row>
      <br>
      <br>
      <br>
      <v-img
        :src="require('@/assets/covers.jpeg')"
        height="500"
        contain
      />
      <br>
      <br>
      <base-section-heading
        title="Product Specification"
        class="pb-0"
      />
      <v-simple-table>
        <template #default>
          <tbody>
            <tr
              v-for="item in specs"
              :key="item.name"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.desc }}<br>{{ item.descSec }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProductsSpec',

    components: {
      NewsCardProducts: () => import('@/components/news/CardProducts'),
    },

    data: () => ({
      articles: [
        {
          title: 'Examination Gloves',
          html: 'Nitrile, powder-free examination gloves for superior personal barrier protection<ul><li>Beaded cuff design and textured fingertips for confident, secure grip</li><li>Soft, durable nitrile material provides excellent protection and flexibility</li><li>Tested and comply according to ASTM D6319 and EN455 standards</li></ul>',
          readMore: true,
          src: require('@/assets/product_1.jpg'),
        },
        {
          title: 'Food contact Gloves',
          html: 'Tested and approved according to 21 CFR 177.2600, Safe for Food Contact<ul><li>Textured Nitrile for enhance tactility</li><li>Examination grade and food contact approved</li><li>Consistent performance</li></ul>',
          readMore: true,
          src: require('@/assets/product_2.jpg'),
        },
        {
          title: 'Chemotherapy Gloves',
          html: 'Nitrile, powder-free examination gloves with chemotherapy drugs permeation test<br> <ul><li>Extra protection for chemotherapy drugs handling or chemotherapy waste disposal</li><li>Achieve high resistance to permeation by commonly used chemotherapy drugs</li><li>Permeation tested as per ASTM D6978-05 by accredited lab</li></ul>',
          readMore: true,
          src: require('@/assets/product_3.jpg'),
        },
        {
          title: 'PPE with CE Category III Gloves',
          html: 'Chemical resistant gloves<br> <ul><li>Compliance to EN420 and EN374 standards</li><li>Tested against 6 standard defined chemicals with breakthrough time at least 30 minutes</li><li>Provide highest level of protection against specific hazardous chemical handling and micro-organisms</li></ul>',
          readMore: true,
          src: require('@/assets/product_4.jpg'),
        },
      ],
      specs: [
        {
          name: 'TYPE',
          desc: 'NON-STERILE, POWDER FREE',
        },
        {
          name: 'COLORS',
          desc: 'REGULAR BLUE, BLACK, WHITE (SUPPORT OEM MANUFACTURING)',
        },
        {
          name: 'DESIGN FEATURES',
          desc: 'AMBIDEXTROUS, FINGER & PALM TEXTURED, BEADED CUFF',
        },
        {
          name: 'FINISHING',
          desc: 'ONLINE CHLORINATED',
        },
        {
          name: 'SIZE',
          desc: 'XS, S, M, L, XL, XXL',
        },
        {
          name: 'STANDARD PACKAGING',
          desc: '100 PCS X 10 INNER BOXES PER CARTON (XS TO L)',
          descSec: '90 PCS X 10 INNER BOXES PER CARTON (XL TO XXL)',
        },
        {
          name: 'LENGTH',
          desc: 'MIN. 240 MM',
        },
        {
          name: 'THICKNESS AT FINGER',
          desc: 'MIN. 0.09 MM',
        },
      ],
    }),
  }
</script>
